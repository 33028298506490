<template>
  <base-section id="order">
    <base-order-form />
  </base-section>
</template>

<script>
  export default {
    name: 'Order',  
    
  }
</script>
